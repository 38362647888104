<template>
    <div class="home">
        <div class="page-container">
            <PageView />
        </div>
    </div>
</template>

<script>
import PageView from '@common/components/cms_app/PageView.vue';

export default {
    name: "Home",
    components: {
        PageView
    },
    setup() {
        return {
        }
    }
};
</script>

<style scoped>
</style>
